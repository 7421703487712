import React, { useEffect, useState } from 'react';
import '../../Artists/ArtistsProfile.css';
import DoknowsworldImage from '../../../imgs/artists-imgs/doknowsworld-banner.jpg';
import { FaInstagram, FaFacebook, FaYoutube, FaTwitter, FaTiktok  } from 'react-icons/fa';

function DoknowsworldPage() {
 // Placeholder follower counts
 const [followerCounts] = useState({
  twitter: '154K',
  facebook: '1.7K',
  instagram: '755K',
  youtube: '252K',
  tiktok: '1.7M',
});

useEffect(() => {
  window.scrollTo(0, 0);
  // Fetch real follower counts from APIs here
}, []);

return (
  <div className="artist-detail">
    <div className="artist-hero" style={{ backgroundImage: `url(${DoknowsworldImage})` }}>
      <div className="artist-hero-content">
        <div className="artist-name-container">
          <h1 className="artist-name">Doknowsworld</h1>
        </div>
      </div>
    </div>
    <div className="artist-content">
      <div className="artist-bio">
        <h2>Biography</h2>
        <p>
        DOKNOW IS A YOUNG COMEDIAN WHO MADE HIS RISE THROUGH INSTAGRAM/TIKTOK REELS  AND TOOK OVER THE INTERNET. 
        MOST KNOWN FOR HIS FUNNY/ CRAZY THOUGHTS ON REAL LIFE SITUATIONS, RELATIONSHIPS AND MORE. 
        HES ALSO IS PART OF BROWN BAG PODCAST, FIGGMUNITY WORLD AND MORE.
        </p>
      </div>
      
      <div className="social-grid">
          {/* Facebook */}
          <a
            href="https://www.facebook.com/yourpage" // Add your actual link
            target="_blank"
            rel="noopener noreferrer"
            className="social-box facebook"
          >
            <FaFacebook className="social-icon" />
            <div className="follower-count">{followerCounts.facebook}</div>
            <div className="follower-text">Fans</div>
          </a>

          {/* Twitter */}
          <a
            href="https://x.com/doknowsworld" // Add your actual link
            target="_blank"
            rel="noopener noreferrer"
            className="social-box twitter"
          >
            <FaTwitter className="social-icon" />
            <div className="follower-count">{followerCounts.twitter}</div>
            <div className="follower-text">Followers</div>
          </a>

          {/* Instagram */}
          <a
            href="https://www.instagram.com/doknowsworld" // Add your actual link
            target="_blank"
            rel="noopener noreferrer"
            className="social-box instagram"
          >
            <FaInstagram className="social-icon" />
            <div className="follower-count">{followerCounts.instagram}</div>
            <div className="follower-text">Followers</div>
          </a>

          {/* YouTube */}
          <a
            href="https://www.youtube.com/channel/UCCyVJijfUKYnYv-oaj42DYw" // Add your actual link
            target="_blank"
            rel="noopener noreferrer"
            className="social-box youtube"
          >
            <FaYoutube className="social-icon" />
            <div className="follower-count">{followerCounts.youtube}</div>
            <div className="follower-text">Subscribers</div>
          </a>

          {/* TikTok */}
          <a
            href="https://www.tiktok.com/@doknowsworld" // Add your actual link
            target="_blank"
            rel="noopener noreferrer"
            className="social-box tiktok"
          >
            <FaTiktok className="social-icon" />
            <div className="follower-count">{followerCounts.tiktok}</div>
            <div className="follower-text">Followers</div>
          </a>
        </div>
      </div>
    </div>
);

}

export default DoknowsworldPage;
