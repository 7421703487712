import React, { useEffect, useState } from 'react';
import '../../Artists/ArtistsProfile.css';
import JerryGImage from '../../../imgs/artists-imgs/jerryg-banner.jpg';
import { FaInstagram, FaFacebook, FaYoutube, FaTwitter, FaTiktok  } from 'react-icons/fa';

function JerryGPage() {
 // Placeholder follower counts
 const [followerCounts] = useState({
  twitter: '154K',
  facebook: '82K',
  instagram: '717K',
  youtube: '15.4K',
  tiktok: '594.8K',
});

useEffect(() => {
  window.scrollTo(0, 0);
  // Fetch real follower counts from APIs here
}, []);

return (
  <div className="artist-detail">
    <div className="artist-hero" style={{ backgroundImage: `url(${JerryGImage})` }}>
      <div className="artist-hero-content">
        <div className="artist-name-container">
          <h1 className="artist-name">Jerry G</h1>
        </div>
      </div>
    </div>
    <div className="artist-content">
      <div className="artist-bio">
        <h2>Biography</h2>
        <p>
        <p>
        Jerry Garcia has stormed his way onto the national comedy scene as one of the top-rated comedians and is truly a must-see performer. 
        A father of three boys, Jerry allows us to enter his world and laugh hysterically with his charm, witty puns, and punchlines using his young, 
        hip, real-life flavor that easily crosses over to all audiences. His views on life, relationships, 
        his modest upbringings, and everyday struggles captivate audiences from all walks of life. No matter where you’re from, the jokes hit home.
      </p>

      <p>
        Jerry’s presence, strong delivery, and likability on stage help him connect and interact with audience members in the most memorable way. 
        Fans leave Jerry’s performances with the feeling of having met a new funny friend.
      </p>

      <p>
        Jerry Garcia's credits continue to grow year after year, 
        beginning in 2016 with Fox's Laughs comedy series. In 2017, 
        Jerry made his Netflix debut co-starring in Chingo Bling’s “They Can’t Deport Us All” Comedy Special. 
        In 2018, Jerry continued to rise as he made his HBO debut on "Entre Nos" Comedy Series. 
        A stand-alone HBO Max comedy special appropriately titled "It's Not My Weekend" premiered in the summer of 2019. 
        Earlier in 2019, Jerry crossed over to acting in 2023 Guest Starring on George Lopez sitcom Lopez vs Lopez.
      </p>

      <h3>Other Credits:</h3>
      <ul>
        <li>2015 Armed Forces Ent: Turkey, Jordan, Syria, and Greece.</li>
        <li>2016 San Diego Comedy Festival; Best Comedian Award.</li>
        <li>2017 LA’s Funniest Comic Contest: First Place.</li>
        <li>2017 They Can’t Deport Us All – Netflix.</li>
        <li>2019 Ploey Animated Film.</li>
        <li>2019 Entre Nos Comedy Special HBO MAX.</li>
        <li>2020 “It’s Not My Weekend” HBO MAX Comedy Special.</li>
        <li>2021 Imagen Award Nominee (Best Reality – Comedy Special).</li>
        <li>2021 King of Downey; Sitcom (Pilot).</li>
        <li>2023 Sitcom; Lopez vs Lopez – NBC/Peacock.</li>
      </ul>

      <h3>Present:</h3>
      <ul>
        <li>Latin Kings of Comedy Tour.</li>
        <li>American Wannabes Podcast.</li>
        <li>Tragos Amargos Podcast.</li>
        <li>Los Toxicos Comedy Tour.</li>
      </ul>
        </p>
      </div>
      
      <div className="social-grid">
          {/* Facebook */}
          <a
            href="https://www.facebook.com/yourpage" // Add your actual link
            target="_blank"
            rel="noopener noreferrer"
            className="social-box facebook"
          >
            <FaFacebook className="social-icon" />
            <div className="follower-count">{followerCounts.facebook}</div>
            <div className="follower-text">Fans</div>
          </a>

          {/* Twitter */}
          <a
            href="https://twitter.com/concretelive" // Add your actual link
            target="_blank"
            rel="noopener noreferrer"
            className="social-box twitter"
          >
            <FaTwitter className="social-icon" />
            <div className="follower-count">{followerCounts.twitter}</div>
            <div className="follower-text">Followers</div>
          </a>

          {/* Instagram */}
          <a
            href="https://www.instagram.com/concretelive" // Add your actual link
            target="_blank"
            rel="noopener noreferrer"
            className="social-box instagram"
          >
            <FaInstagram className="social-icon" />
            <div className="follower-count">{followerCounts.instagram}</div>
            <div className="follower-text">Followers</div>
          </a>

          {/* YouTube */}
          <a
            href="https://www.youtube.com/@concretecomedy" // Add your actual link
            target="_blank"
            rel="noopener noreferrer"
            className="social-box youtube"
          >
            <FaYoutube className="social-icon" />
            <div className="follower-count">{followerCounts.youtube}</div>
            <div className="follower-text">Subscribers</div>
          </a>

          {/* TikTok */}
          <a
            href="https://www.tiktok.com/@concretecomedy" // Add your actual link
            target="_blank"
            rel="noopener noreferrer"
            className="social-box tiktok"
          >
            <FaTiktok className="social-icon" />
            <div className="follower-count">{followerCounts.tiktok}</div>
            <div className="follower-text">Followers</div>
          </a>
        </div>
      </div>
    </div>
);

}

export default JerryGPage;