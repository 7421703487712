import React, { useState, useEffect } from 'react';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setClick(false); // Close the mobile menu on resize if width is greater than 768px
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="navbar">
      <div className="navbar-left">
        <img src={process.env.PUBLIC_URL + '/assets/lrm-menu.png'} alt="Logo" className="logo-image" />
      </div>
      <div className="navbar-center">
        <a href="/" onClick={closeMobileMenu}>HOME</a>
        <a href="/artists" onClick={closeMobileMenu}>COMIDIANS</a>
        <a href="/" onClick={closeMobileMenu}>SHOWS</a>
        <a href="/" onClick={closeMobileMenu}>ABOUT US</a>
        <a href="/" onClick={closeMobileMenu}>CONTACT</a>
      </div>
      <div className="navbar-right">
        <a href="https://www.facebook.com/profile.php?id=61552184496950" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a>
        <a href="https://www.instagram.com/lrmmanagement" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a>
        <a href="https://www.threads.net/@lrmmanagement" target="_blank" rel="noopener noreferrer"><i className="fas fa-at" /></a>
      </div>
      {/* Mobile Navbar */}
      <div className="navbar-mobile">
        <img src={process.env.PUBLIC_URL + '/assets/lrm-menu.png'} alt="Logo" className="logo-image" />
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
      </div>
      <div className={`navbar-menu ${click ? 'open' : ''}`}>
        <a href="/" onClick={closeMobileMenu}>HOME</a>
        <a href="/artists" onClick={closeMobileMenu}>ARTISTS</a>
        <a href="/" onClick={closeMobileMenu}>VIDEOS</a>
        <a href="/"  onClick={closeMobileMenu}>MERCH</a>
        <a href="/" onClick={closeMobileMenu}>SHOWS</a>
        <a href="/" onClick={closeMobileMenu}>CONTACT</a>
        <div className="social-icons">
        <a href="https://www.facebook.com/profile.php?id=61552184496950" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a>
        <a href="https://www.instagram.com/lrmmanagement" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a>
        <a href="https://www.threads.net/@lrmmanagement" target="_blank" rel="noopener noreferrer"><i className="fas fa-at" /></a>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
