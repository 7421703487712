

import React from "react";
import "./EventsSection.css";


const events = [
  {
    title: "KS EVENT",
    location: "Atlanta, GA, USA",
    date: "SAT, OCT 29",
  },
  {
    title: "KS EVENT",
    location: "Austin, TX, USA",
    date: "SAT, NOV 26",
  },
  {
    title: "KS EVENT",
    location: "Thornville, OH, USA",
    date: "FRI, SEP 30",
  },
  {
    title: "KS EVENT",
    location: "Monterey, CA, USA",
    date: "FRI, OCT 14",
  }
];

const EventsSection = () => {
  return (
    <div className="event-list">
      <div className="event-image">
        <img src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhNWneSS9CpYwRDaaFwq0jQt0phw2t4yY-_AWqFe90vmcZUEiewVq9yIyZQ0mKgtOcbaLiCukoFNkwTOeHOLcFV2zzJg2DFnAQVJmsuJzUpUAai9hpnnu0IeWbfsqT12t0MwlIKLwjhzZQ/s1600/NEW+KS+Icon299dpi.jpg" alt="Event" />
      </div>
      <div className="event-details">
      <h2 className="event-heading">events</h2>
        <h1>Upcoming events</h1>
        <p>Track to get concert, live stream and tour updates.</p>
        <ul>
          {events.map((event, index) => (
            <li key={index} className="event-item">
              <div className="event-info">
                <h3 className="event-title">{event.title}</h3>
                <p>{event.location}</p>
              </div>
              <div className="event-date">
                <p>{event.date}</p>
                <button className="ticket-button">Tickets</button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default EventsSection;