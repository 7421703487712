
import React from 'react'
import "../../App.css";
import './MobileHeroSection.css';

function MobileHeroSection() {

    return (
        <div className='hero-wrap'>
            <div className='hero-container'>
                {/* <div className='text-content'>
                    <div className="out-now-container">
                        <h5 className="subheading new">New</h5>
                        <h5 className="subheading">Album</h5>
                        <div className="out-now-line"></div>
                        <h5 className="subheading">Out Now</h5>
                    </div>
                    <h1>LA ESQUINA</h1>
                    <div className="cta-buttons">
                        <button className='btns'>Stream</button>
                        <button className='btns'>Download</button>
                        <button className='btns'>Buy</button>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default MobileHeroSection