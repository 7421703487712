import React from "react";
import { Link } from "react-router-dom";
import "./Artists.css";
import ConcreteImg from '../../imgs/artists-imgs/concrete.jpg';
import JerryGImg from '../../imgs/artists-imgs/jerry-garcia.jpeg';
import DoknowsworldImg from '../../imgs/artists-imgs/Doknowsworld.png';
import OscarMImg from '../../imgs/artists-imgs/oscarm.jpg';

const artistsData = [
  { name: "Concrete", image: ConcreteImg, link: "/Concrete", status: "Comidian" },
  { name: "Jerry Garcia", image: JerryGImg, link: "/JerryG", status: "Comidian" },
  { name: "Doknowsworld", image:  DoknowsworldImg, link: "/Doknowsworld", status: "Comidian" },
  { name: "Oscar Miranda", image: OscarMImg, link: "/Doknowsworld", status: "Comidian" },
];

function Artists() {
  return (
    <div className="artists-section">
      <h1>Meet the Comedy Masters</h1>
      <div className="artists-grid">
        {artistsData.map((artist, index) => (
          <div key={index} className="artist-card">
            <Link to={artist.link}>
              <div className="artist-image-wrapper">
                <img src={artist.image} alt={artist.name} className="artist-image" />
                <div className="artist-details">
                  <h3 className="artist-card-name">{artist.name}</h3>
                  <p className="artist-status">{artist.status}</p>
                </div>
                <div className="arrow-icon">&#9662;</div> {/* Down arrow icon */}
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Artists;